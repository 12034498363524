import type { NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

type PublicPage<P = { token: string }, IP = P> = NextPage<P, IP> & {
  isPublicPage: boolean;
};

const Login: PublicPage = () => {
  useEffect(() => {
    function handleLogin() {
      signIn('motus-identity-server', { callbackUrl: sessionStorage.getItem('redirectUrl') ?? '/opinmal' });
    }
    handleLogin();
  }, []);

  return (
    <Box sx={{ background: '#f0f0f0 !important', height: '100%', width: '100%' }}>
      <Box sx={{ position: 'absolute', m: '0px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
        <Typography sx={{ pt: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
          Vinn úr auðkenningu...
        </Typography>
      </Box>
    </Box>
  );
};

Login.isPublicPage = true;

export default Login;
